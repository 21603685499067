import React, { Fragment } from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import { OpenInvoiceCardProps } from 'components/Core/OpenInvoices/Notice/OpenInvoicesNotice';
import FormattedDateNoTimeZone from 'components/Shared/FormattedDateNoTimeZone/FormattedDateNoTimeZone';
import CheckList from 'components/Shared/Icons/CheckList';
import { formatISO8601Date } from 'shared/clientUtils';
import { colors } from 'styles/cp';

export interface Props extends OpenInvoiceCardProps {
  isSuccessScreen?: boolean;
  autoPayInfo?: { isAutoPay: boolean; isAutoPayOn: boolean; companyName: string };
  index: number;
  saleStatus?: {
    name: string;
    color: string;
  };
  saleType?: string;
  hrDividersConfig?: {
    topRowHr?: boolean;
    bottomRowHr?: boolean;
    styles: {
      width?: string;
      marginString?: string;
    };
  };
  onHoverBackground?: string;
  isDisplayWhenNoBalance?: boolean;
  viewInvoiceCallback?: () => void;
}

const OpenInvoiceCard: React.FC<Props> = ({
  index,
  isSuccessScreen,
  autoPayInfo: { isAutoPay = false, isAutoPayOn = false } = {},
  balance,
  docNumber,
  link,
  dueDate,
  saleStatus,
  saleType,
  onHoverBackground,
  isDisplayWhenNoBalance = false,
  viewInvoiceCallback,
}) => {
  if (!dueDate || !link || !docNumber || (!balance && !isDisplayWhenNoBalance)) {
    return <Fragment />;
  }

  let due = formatISO8601Date(dueDate);

  if (!due) {
    return <Fragment />;
  }

  const today = new Date();
  const msInDay = 24 * 60 * 60 * 1000;

  due.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const diff = (+due - +today) / msInDay;

  const defaultViewInvoice = () => {
    window.open(link, '_blank');
  };

  const viewInvoice = viewInvoiceCallback || defaultViewInvoice;

  // @ts-ignore
  saleType = saleType ? <FormattedMessage id={saleType} defaultMessage={saleType} /> : 'Invoice';
  return (
    <Fragment>
      <div className={`card`} data-cy={`OpenInvoiceCard-${docNumber}`}>
        <div className="balance">
          <FormattedNumber value={balance} style="currency" currency="USD" />
          {((!isSuccessScreen && !isAutoPay) || (isAutoPay && index === 0)) && (
            <div className="invoice-number">
              {saleType}&nbsp;
              {docNumber}
            </div>
          )}
        </div>
        <div className="info">
          {isSuccessScreen && (
            <Fragment>
              {saleType}&nbsp;
              {docNumber}
              <span className="separator" />
            </Fragment>
          )}
          {isAutoPay && index === 0 && <span>Due on </span>}
          {isAutoPay && index > 0 && <span>Expected on </span>}
          <FormattedDateNoTimeZone value={dueDate} />
          {saleStatus && (
            <div className="due-info">
              <span className={`dot ${saleStatus.color}-dot`} />
              <span className="text">
                <FormattedMessage id={saleStatus.name} defaultMessage={saleStatus.name} />
              </span>
            </div>
          )}
          {!saleStatus && isAutoPayOn && index > 0 && (
            <div className="due-info autopay">
              <span className="dot" />
              <span className="text">Autopay</span>
            </div>
          )}
          {!saleStatus && !isAutoPay && diff < 0 && (
            <div className="due-info">
              <span className="dot" />
              <span className="overdue">Overdue</span>
            </div>
          )}
          {!saleStatus && !isAutoPay && diff >= 0 && diff <= 2 && (
            <div className="due-info">
              <span className="dot orange-dot" />
              Due in {diff} days
            </div>
          )}
        </div>

        {isAutoPay && isAutoPayOn && index >= 1 && (
          <div className="cta autopay-icon" aria-label="checklist icon">
            <CheckList />
          </div>
        )}

        {!isAutoPay && (
          <div className="cta" onClick={() => viewInvoice()}>
            View
          </div>
        )}
      </div>
      {/*language=SCSS*/}
      <style jsx>{`
        .card {
          padding: 12px 14px;
          font-size: ${isSuccessScreen ? '18px' : '14px'};
          font-family: AvenirNextforINTUIT-Regular;
          position: relative;
          :hover {
            background-color: ${onHoverBackground};
          }
          .balance {
            padding-bottom: 4px;
            padding-left: 8px;
            font-family: AvenirNextforINTUIT-Medium;

            .invoice-number {
              padding-left: 12px;
              display: inline-block;
              font-size: 14px;
              color: ${colors.gray02};
              font-family: AvenirNextforINTUIT-Regular;
            }
          }

          .info {
            padding-left: 8px;
            font-size: ${isSuccessScreen ? '14px' : '12px'};
            color: ${colors.gray02};

            .separator {
              width: 1px;
              height: 22px;
              display: inline-block;
              background-color: gray;
              margin: 0 8px -4px;
            }

            .dot {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              display: inline-block;
              margin-right: 6px;
              margin-bottom: 2px;
            }

            .due-info {
              display: inline-block;
              padding-left: 8px;

              .orange-dot {
                background-color: orange;
              }

              &.autopay {
                .dot {
                  background-color: ${colors.blue};
                }
                .text {
                  color: ${colors.blue};
                }
              }
            }
          }

          .cta {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
            color: ${colors.blue};
            cursor: pointer;

            &.autopay-icon {
              cursor: default;
              right: 5px;
            }

            a {
              color: inherit;
              text-decoration: none;
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default OpenInvoiceCard;
